import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#173c49',
        },
        secondary: {
            main: '#fff',
        }
    },
});

export default theme