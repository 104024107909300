import React from 'react';
import './About.css'

class About extends React.Component {

  render() {
    return (
      <div className="about">
        <div className="content">
        <h2>About</h2>
          <p>View previous McGill notes, exams, solutions, assignments, etc. uploaded by students.</p>
          <p>If you use this service, please consider donating by clicking the donate button at the bottom right of this window. I'm a student at McGill myself and I make no money from this, so I just need enough to cover the costs of keeping it running. I will have to take it down if I can't afford it. Thank you!</p>
          <h3>FAQs</h3>
          <h4>Where are these documents from?</h4>
          <p> These are all the documents that Docuum (RIP) had and more. </p>
          <h4>Can I upload documents?</h4>
          <p>Not yet. I quickly made this website for students to use ASAP. If you want a document on here, you can email the
            document to <a href="mailto:mycookiesca@gmail.com">mycookiesca@gmail.com</a> with the respective course code and 
            I'll put it up as soon as I can. 
          </p>
        </div>
      </div>
    )
  }
}

export default About;
