import React from 'react';
import './LandingPage.css';
import { Input } from '@material-ui/core';

class LandingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      courseCode: null
    }
  }

  handleChange = (event) => {
    this.setState({
      courseCode: event.target.value
    })
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.props.history.push('/course/' + this.state.courseCode)
    }
  }

  render() {
    return (
      <div className="landingPage">
        <p className="title">MyCookies</p>
        <div className="input-wrapper">
          <Input
            style={{
              color: '#fff'
            }}
            className="search"
            placeholder="Enter a course code. Example: PHYS214" 
            onChange={this.handleChange} 
            onKeyDown={this.handleKeyDown}
            />
        </div>
      </div>
    )
  }
}

export default LandingPage;
