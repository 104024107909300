import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom'

const footerItemClass = {
  color: 'rgba(255,255,255,0.1)',
  margin: '0 4px',
  fontSize: '0.8em',
  cursor: 'pointer'
}

class Footer extends React.Component {

  render() {
    return (
      <div className="footer">
        <Link className="footer-item" style={footerItemClass} to="/privacypolicy" > Privacy Policy </Link>
        <Link className="footer-item" style={footerItemClass} to="/terms" > Terms of Service </Link>
      </div>
    )
  }
}

export default Footer;
