import React from 'react';
import './Course.css';
import { List, ListItem, ListItemText, CircularProgress, Grid } from '@material-ui/core';
import { API_URL, S3_URL } from '../../config'

const axios = require('axios');

class Course extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      docs: null
    }
  }

  componentDidMount() {
    console.log("test: " + process.env.REACT_APP_TEST)
    axios.get(API_URL + '/course/' + this.props.match.params.courseCode.replace(/[^\w]|_/g, "").toUpperCase())
      .then((res) => {
        this.setState({docs: res.data.widgets})
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    if (this.state.docs !== null) {
      return (
        <div className="course-container">
          <div className="course-inner-container">
            <div id="course-title">{this.props.match.params.courseCode}</div>
            {this.renderDocumentList(this.state.docs)}
          </div>
        </div>
      )
    } else {
      return <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{minHeight: "100vh"}}>
        <CircularProgress/>
      </Grid>
    }
  }

  renderDocumentList = (docs) => {
    if (docs.length === 0) return <p> No documents found. Either this course does not exist or no documents have been uploaded. </p>
    return (<List className="doc">
      {docs.map((doc, index) => <ListItem button component="a" href={S3_URL + doc} target="_blank" key={index}>
        <ListItemText>{doc.split('/')[1]}</ListItemText>
      </ListItem>)}
    </List>)
  }
}

export default Course;