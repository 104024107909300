import React from 'react';
import './Donate.css';

class Donate extends React.Component {
    render() {
        return (
            <div>
                <a class="dbox-donation-button donate-button" href="https://donorbox.org/mycookies-survival-fund" >Donate</a>
            </div>
        )
    }
}

export default Donate;