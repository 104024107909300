import React from 'react';
import LandingPage from './components/LandingPage/LandingPage'
import Course from './components/Course/Course'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import Footer from './components/Footer/Footer';
import TermsOfService from './components/TermsOfService/TermsOfService';
import { Router } from 'react-router';
import { Route, Switch, Link } from "react-router-dom";
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createBrowserHistory } from 'history';
import theme from './theme'
import './App.css';
import logo from './images/cookie-white.png'

import ReactGA from 'react-ga';
import About from './components/About/About';
import Donate from './components/Donate/Donate';
ReactGA.initialize('UA-148026812-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export const history = createBrowserHistory();

class App extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router history={history}>
          <AppBar position="static" className="appBar">
              <Toolbar>
                  <Typography variant="title" className="logo-wrapper" color="inherit">
                    <img alt="mycookies logo" id="logo" src={logo} onClick={() => history.push('/')}/>
                  </Typography>
                  <Donate />
                  <Link style={{color: 'rgba(255,255,255,0.5)', textDecoration: 'none'}} to="/about" > About </Link>
              </Toolbar>
          </AppBar>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path ="/course/:courseCode" component={Course} />
            <Route path ="/privacypolicy" component={PrivacyPolicy} />
            <Route path ="/terms" component={TermsOfService} />
            <Route path ="/about" component={About} />
          </Switch>
        <Footer/>
        </Router>
      </MuiThemeProvider>
    )
  }
}

export default App;
